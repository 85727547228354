<template>
    <div>
      <b-modal
        id="modal-input"
        size="lg"
        centered
        title="Tambah Data Rekam Medis Pasien"
        header-bg-variant="primary"
        header-text-variant="light"
      >
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Pasien <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('nama_lengkap')"
              type="text"
              v-model="$v.data.nama_lengkap.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Jenis Kelamin <span class="text-danger">*</span>
            </template>
            <b-form-select
              :state="checkIfValid('jenis_kelamin')"
              v-model="$v.data.jenis_kelamin.$model"
              :options="listJenisKelamin"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tempat Lahir
            </template>
            <b-form-input
              :state="checkIfValid('tempat_lahir')"
              type="text"
              v-model="$v.data.tempat_lahir.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tanggal Lahir <span class="text-danger">*</span>
            </template>
            <date-picker
              style="width: 100%"
              :state="checkIfValid('tanggal_lahir')"
              v-model="$v.data.tanggal_lahir.$model"
            ></date-picker>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Alamat Sekarang<span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('alamat_sekarang')"
              type="text"
              v-model="$v.data.alamat_sekarang.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Alamat KTP<span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('alamat_ktp')"
              type="text"
              v-model="$v.data.alamat_ktp.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Agama 
            </template>
            <b-form-select
              :state="checkIfValid('agama')"
              :options="listAgama"
              v-model="$v.data.agama.$model"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              NPWP
            </template>
            <b-form-input
              :state="checkIfValid('npwp')"
              type="text"
              v-model="$v.data.npwp.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No BPJS
            </template>
            <b-input-group prepend="" class="">
              <b-form-input
                id="no_bpjs"
                v-model="$v.data.no_bpjs.$model"
                :state="checkIfValid('no_bpjs')"
              ></b-form-input>
              <b-input-group-append>
                <b-button 
                  variant="success" 
                  :disabled="busy"
                  @click="cekAsuransiBPJS('noka')"
                >cek no BPJS</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group label-cols-md="3" v-if="data.pst_prb">
            <template v-slot:label>
              Diabetes
            </template>
            <b-form-input
              id="pst_prb"
              v-model="data.pst_prb"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3" v-if="data.pst_prol">
            <template v-slot:label>
              Prolanis  
            </template>
            <b-form-input
              id="pst_prol"
              v-model="data.pst_prol"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              NIK <span class="text-danger">*</span>
            </template>
            <b-input-group prepend="" class="">
              <b-form-input
                :state="checkIfValid('nik')"
                type="text"
                v-model="$v.data.nik.$model"
              ></b-form-input>
              <b-input-group-append>
                <b-button 
                  variant="primary" 
                  @click="cekAsuransiBPJS('nik')"
                >cek BPJS by NIK</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Pasangan
            </template>
            <b-form-input
              :state="checkIfValid('nama_pasangan')"
              type="text"
              v-model="$v.data.nama_pasangan.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Ayah
            </template>
            <b-form-input
              :state="checkIfValid('nama_ayah')"
              type="text"
              v-model="$v.data.nama_ayah.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Ibu
            </template>
            <b-form-input
              :state="checkIfValid('nama_ibu')"
              type="text"
              v-model="$v.data.nama_ibu.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Penanggung
            </template>
            <b-form-input
              :state="checkIfValid('nama_penjamin')"
              type="text"
              v-model="$v.data.nama_penjamin.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Hubungan Penjamin
            </template>
            <b-form-input
              :state="checkIfValid('hubungan_penjamin')"
              type="text"
              v-model="$v.data.hubungan_penjamin.$model"
            ></b-form-input>
          </b-form-group>
          <!-- <b-form-group label-cols-md="3">
            <template v-slot:label>
              Dibuat Oleh <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('dibuat_oleh')"
              type="text"
              v-model="$v.data.dibuat_oleh.$model"
            ></b-form-input>
          </b-form-group> -->
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Pekerjaan
            </template>
            <b-form-input
              :state="checkIfValid('pekerjaan')"
              type="text"
              v-model="$v.data.pekerjaan.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No Telepon
            </template>
            <b-form-input
              :state="checkIfValid('no_telp')"
              type="text"
              v-model="$v.data.no_telp.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No Asuransi Lain
            </template>
            <b-form-input
              :state="checkIfValid('no_asuransi_lain')"
              type="text"
              v-model="$v.data.no_asuransi_lain.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No Telp Penjamin
            </template>
            <b-form-input
              :state="checkIfValid('no_telp_penjamin')"
              type="text"
              v-model="$v.data.no_telp_penjamin.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No Paspor
            </template>
            <b-form-input
              :state="checkIfValid('no_paspor')"
              type="text"
              v-model="$v.data.no_paspor.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Asal Negara 
            </template>
            <b-form-input
              :state="checkIfValid('asal_negara')"
              type="text"
              v-model="$v.data.asal_negara.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No Kk
            </template>
            <b-form-input
              :state="checkIfValid('no_kk')"
              type="text"
              v-model="$v.data.no_kk.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Status Perkawinan
            </template>
            <multiselect
              v-model="data.status_perkawinan"
              :state="checkIfValid('status_perkawinan')"
              :options="$store.state.data_static.status_perkawinan"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="-- Pilih Status Perkawinan --"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Status Keluarga
            </template>
            <multiselect
              v-model="data.status_keluarga"
              :state="checkIfValid('status_keluarga')"
              :options="$store.state.data_static.status_keluarga"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="-- Pilih Status Keluarga --"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Pendidikan
            </template>
            <multiselect
              v-model="data.pendidikan_id"
              :state="checkIfValid('pendidikan_id')"
              :options="pendidikan"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_pendidikan"
              track-by="ms_pendidikan_id"
              placeholder="-- Pilih Pendidikan --"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Golongan Darah
            </template>
            <multiselect
              v-model="$v.data.golongan_darah_id.$model"
              :state="checkIfValid('golongan_darah_id')"
              :options="golongan_darah"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_golongan_darah"
              track-by="ms_golongan_darah_id"
              placeholder="-- Pilih Golongan Darah --"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Suku Bangsa
            </template>
            <multiselect
                v-model="$v.data.etnis_id.$model"
                :state="checkIfValid('etnis_id')"
                :options="etnis"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="nama_etnis"
                track-by="ms_etnis_id"
                placeholder="-- Pilih Etnis --"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Provinsi <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.ms_provinsi_id.$model"
              :state="checkIfValid('ms_provinsi_id')"
              :options="provinsi"
              @input="getKota(data.ms_provinsi_id)"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              label="nama_provinsi"
              track-by="ms_provinsi_id"
              placeholder="-- Pilih Provinsi --"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kota <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.ms_kota_id.$model"
              :state="checkIfValid('ms_kota_id')"
              :options="kota"
              @input="getKecamatan(data.ms_kota_id)"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_kota"
              track-by="ms_kota_id"
              placeholder="-- Pilih Kabupaten/Kota --"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kecamatan <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.ms_kecamatan_id.$model"
              :state="checkIfValid('ms_kecamatan_id')"
              :options="kec"
              @input="getKelurahan(data.ms_kecamatan_id)"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_kecamatan"
              track-by="ms_kecamatan_id"
              placeholder="-- Pilih Kecamatan --"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kelurahan <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.kelurahan_id.$model"
              :state="checkIfValid('kelurahan_id')"
              :options="kel"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="nama_kelurahan"
              track-by="ms_kelurahan_id"
              placeholder="-- Pilih Kelurahan --"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No. RM Lama
            </template>
            <b-form-input
              :state="checkIfValid('no_rm_lama')"
              type="text"
              v-model="$v.data.no_rm_lama.$model"
            ></b-form-input>
          </b-form-group>
        </b-form>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
            Batal
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy || !isValid"
            @click="simpan()"
            >{{ button }}</b-button
          >
        </template>
      </b-modal>
    </div>
</template>
  
<script>
  import { validationMixin } from "vuelidate";
  import { required, minLength, maxLength } from "vuelidate/lib/validators";
  import Multiselect from "vue-multiselect";
  import DatePicker from "vue2-datepicker";
  import _ from "lodash";
  import "vue2-datepicker/index.css";
  export default {
    name: "modalInput",
    props: ["provinsi", "pekerjaan", "pendidikan", "etnis", "golongan_darah"],
    data() {
      return {
        data: {
          // nama_lengkap: '11',
          // jenis_kelamin: '11',
          // tempat_lahir: '11',
          // tanggal_lahir: '11',
          // alamat_sekarang: '11',
          // alamat_ktp: '11',
          // agama: '11',
          // npwp: '11',
          // no_bpjs: '11',
          // nik: 1111111111111111,
          // nama_pasangan: '11',
          // nama_ayah: '11',
          // nama_ibu: '11',
          // nama_penjamin: '11',
          // hubungan_penjamin: '11',
          // dibuat_oleh: '11',
          // diperbarui_oleh: '11',
          // pekerjaan: '11',
          // no_telp: '11',
          // no_asuransi_lain: '11',
          // ms_pendidikan_id: '11',
          // ms_golongan_darah_id: '11',
          // ms_etnis_id: '11',
          // ms_provinsi_id: '11',
          // ms_kota_id: '11',
          // ms_kecamatan_id: '11',
          // ms_kelurahan_id: '11',
          no_rm_lama: null,
          nama_lengkap: null,
          jenis_kelamin: null,
          tempat_lahir: null,
          tanggal_lahir: null,
          alamat_sekarang: null,
          alamat_ktp: null,
          agama: null,
          npwp: null,
          no_bpjs: null,
          nik: null,
          nama_pasangan: null,
          nama_ayah: null,
          nama_ibu: null,
          nama_penjamin: null,
          hubungan_penjamin: null,
          // dibuat_oleh: null,
          // diperbarui_oleh: null,
          pekerjaan: null,
          no_telp: null,
          no_asuransi_lain: null,
          no_telp_penjamin: null,
          status_perkawinan: null,
          status_keluarga: null,
          no_paspor: null,
          asal_negara: null,
          no_kk: null,
          ms_pendidikan_id: null,
          ms_golongan_darah_id: null,
          ms_etnis_id: null,
          ms_provinsi_id: null,
          ms_kota_id: null,
          ms_kecamatan_id: null,
          ms_kelurahan_id: null,
          kelurahan_id: null,
          pendidikan_id: null,
          golongan_darah_id: null,
          etnis_id: null,
        },
        kota: [],
        kec: [],
        kel: [],
        listJenisKelamin: [
          { value: "L", text: "Laki-laki" },
          { value: "P", text: "Perempuan" },
        ],
        listAgama: [
          { value: "Islam", text: "Islam" },
          { value: "Kristen", text: "Kristen" },
          { value: "Khatolik", text: "Khatolik" },
          { value: "Hindu", text: "Hindu" },
          { value: "Budha", text: "Budha" },
          { value: "Lainnya", text: "Lainnya" },
        ],
        selected: null,
        busy: false,
        button: "Simpan",
      };
    },
    components: {
      Multiselect,
      DatePicker,
    },
    computed: {
      formString() {
        return JSON.stringify(this.data, null, 4);
      },
      isValid() {
        return !this.$v.data.$invalid;
      },
      isDirty() {
        return this.$v.data.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      data: {
        nama_lengkap: { required },
        jenis_kelamin: { required },
        tempat_lahir: {  },
        tanggal_lahir: { required },
        alamat_sekarang: { required },
        alamat_ktp: { required },
        agama: {  },
        npwp: { },
        no_bpjs: { },
        nik: { 
          required, 
          minLength: minLength(16),
          maxLength: maxLength(16)
        },
        no_rm_lama: { },
        nama_pasangan: { },
        nama_ayah: {  },
        nama_ibu: {  },
        nama_penjamin: { },
        hubungan_penjamin: { },
        // dibuat_oleh: {  },
        // diperbarui_oleh: { required },
        pekerjaan: { },
        no_telp: {  },
        no_asuransi_lain: {  },
        no_telp_penjamin: {  },
        status_perkawinan: {  },
        status_keluarga: {  },
        no_paspor: {  },
        asal_negara: {  },
        no_kk: {  },
        pendidikan_id: {  },
        golongan_darah_id: {  },
        etnis_id: {  },
        ms_provinsi_id: { required },
        ms_kota_id: { required },
        ms_kecamatan_id: { required },
        kelurahan_id: { required },
      },
    },
    methods: {
      checkIfValid(fieldName) {
        const field = this.$v.data[fieldName];
        // console.log('fieldName', fieldName)
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      async cekAsuransiBPJS(jenis_kartu){
        // 0000093115361
        const vm = this
        vm.busy = true
        let nomor_kartu = vm.data.no_bpjs //default
        if(jenis_kartu == 'nik') nomor_kartu = vm.data.nik //jika nik
        vm.data.status_no_bpjs = false
        vm.data.nama_lengkap = null
        vm.data.jenis_kelamin = null
        vm.data.golongan_darah_id = null
        vm.data.tanggal_lahir = null
        vm.data.nik = null
        vm.data.no_telp = null
        vm.data.no_kartu = null
        vm.data.pst_prol = null
        vm.data.pst_prb = null
        vm.data.jenis_peserta_bpjs = null
        vm.data.is_pbi = null
        vm.data.jenis_upah = null
        // console.log(vm.data.no_bpjs)
        if(nomor_kartu && jenis_kartu){
          const res = await vm.$axios.post('/integrasi_pcare/get_peserta_by_jenis_kartu', {nomor_kartu, jenis_kartu})
          // console.log('res', res)
          if(res.data.status == 200){
            const x = res.data.data[0]
            vm.data.no_bpjs = x.noKartu
            vm.data.nama_lengkap = x.nama
            vm.data.jenis_kelamin = x.sex
            vm.data.golongan_darah_id = vm.$findKey(vm.golongan_darah, x.golDarah, true, 'ms_golongan_darah_id')
            vm.data.tanggal_lahir = new Date(x.tglLahir)
            vm.data.nik = x.noKTP
            if(jenis_kartu == 'nik') vm.data.nik = nomor_kartu // karena jika mengecek melalui nik, nik yang diberikan BE kosong
            vm.data.no_telp = x.noHP
            vm.data.no_kartu = x.noKartu
            vm.data.pst_prol = x.pstProl
            vm.data.pst_prb = x.pstPrb
            if(x.jnsPeserta && x.jnsPeserta.length){
              vm.data.jenis_peserta_bpjs  = x.jnsPeserta[0].nama
              if(x.jnsPeserta[0].nama.toLowerCase().includes('pbi')){
                vm.data.is_pbi  = x.jnsPeserta[0].nama
              }
            }
            // vm.data.jenis_upah  = res.data.data[0].pst_prb
            // console.log('bpjs/getPesertaByNoKartu ', x.peserta.noKartu)
            vm.$store.commit("set_alert", { variant: "success", msg: 'No Asuransi Tersedia', showing: true });
            vm.data.status_no_asuransi = true
          }else{
            vm.$store.commit("set_alert", { variant: "danger", msg: res.data.message, showing: true });
          }
        }else{
          vm.$store.commit("set_alert", { variant: "danger", msg: 'No BPJS Tidak Valid', showing: true });
        }
        vm.busy = false
        vm.$v.data.no_bpjs.$reset();
        vm.$v.data.no_bpjs.$touch();
      },
      async getKota(x) {
        // console.log(x, 'ini x');
        this.data.ms_kota_id = null
        this.data.ms_kecamatan_id = null
        this.data.ms_kelurahan_id = null
        let kota = await this.$axios.post("/ms_kota/list", {ms_provinsi_id: x.ms_provinsi_id});
        // console.log(kota.data.data);
        this.kota = kota.data.data;
      },
      async getKecamatan(x) {
        // console.log('x', x);
        this.data.ms_kecamatan_id = null
        this.data.ms_kelurahan_id = null
        let kecamatan = await this.$axios.post("/ms_kecamatan/list", {ms_kab_kota_id: x.ms_kota_id});
        // console.log(kecamatan.data.data);
        this.kec = kecamatan.data.data;
      },
      async getKelurahan(x) {
        this.data.ms_kelurahan_id = null
        let kelurahan = await this.$axios.post("/ms_kelurahan/list", {ms_kecamatan_id: x.ms_kecamatan_id});
        // console.log(kelurahan.data.data);
        this.kel = kelurahan.data.data;
      },
      simpan() {
        let vm = this;
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        if(vm.data.kelurahan_id){
          vm.data.ms_kelurahan_id = vm.data.kelurahan_id.ms_kelurahan_id
        }
        if(vm.data.pendidikan_id){
          vm.data.ms_pendidikan_id = vm.data.pendidikan_id.ms_pendidikan_id
        }
        if(vm.data.golongan_darah_id){
          vm.data.ms_golongan_darah_id = vm.data.golongan_darah_id.ms_golongan_darah_id
        }
        if(vm.data.etnis_id){
          vm.data.ms_etnis_id = vm.data.etnis_id.ms_etnis_id
        }
        console.log(vm.data, 'ini datanya ommm');
        vm.$axios
          .post("/rm/register", vm.data)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$v.$reset();
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN PASIEN",
                showing: true,
              });
              this.$bvModal.hide("modal-input");
              this.reset();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      },
      reset() {
        this.$v.$reset();
        this.data = {

          nama_lengkap: null,
          jenis_kelamin: null,
          tempat_lahir: null,
          tanggal_lahir: null,
          alamat_sekarang: null,
          alamat_ktp: null,
          agama: null,
          npwp: null,
          no_bpjs: null,
          nik: null,
          nama_pasangan: null,
          nama_ayah: null,
          nama_ibu: null,
          nama_penjamin: null,
          hubungan_penjamin: null,
          dibuat_oleh: null,
          // diperbarui_oleh: null,
          pekerjaan: null,
          no_telp: null,
          no_asuransi_lain: null,
          no_telp_penjamin: null,
          status_perkawinan: null,
          status_keluarga: null,
          no_paspor: null,
          asal_negara: null,
          no_kk: null,
          ms_pendidikan_id: null,
          ms_golongan_darah_id: null,
          ms_etnis_id: null,
          ms_provinsi_id: null,
          ms_kota_id: null,
          ms_kecamatan_id: null,
          ms_kelurahan_id: null,
          kelurahan_id: null,
          pendidikan_id: null,
          golongan_darah_id: null,
          etnis_id: null,
        };
      },
    },
  };
  </script>
  