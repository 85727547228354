<template>
    <div>
      <b-modal
        id="modal-detail"
        size="lg"
        centered
        title="Detail Rekam Medis Pasien"
        header-bg-variant="primary"
        header-text-variant="light"
      >
        <b-container fluid>
          <b-row>
            <b-col cols="12" sm="12" md="12" lg="12">
              <h3><strong>DATA PASIEN</strong></h3>
            </b-col>
          </b-row>
          <hr/>
          <b-row>
            <b-col cols="6" sm="6" md="6" lg="6">
              <table>
                <tr>
                    <td>Nama</td>
                    <td style="width:10px">:</td>
                    <td>{{ data.nama_lengkap }}</td>
                </tr>
                <tr>
                    <td>NIK</td>
                    <td>:</td>
                    <td>{{ data.nik }}</td>
                </tr>
                <tr>
                    <td>Tanggal Lahir</td>
                    <td>:</td>
                    <td>{{ data.tgl }}</td>
                </tr>
                <tr>
                    <td>Tempat Lahir</td>
                    <td>:</td>
                    <td>{{ data.tempat_lahir }}</td>
                </tr>
                <tr>
                    <td>Alamat Sekarang</td>
                    <td>:</td>
                    <td>{{ data.alamat_sekarang }}</td>
                </tr>
                <tr>
                    <td>Alamat KTP</td>
                    <td>:</td>
                    <td>{{ data.alamat_ktp }}</td>
                </tr>
                <tr>
                    <td>Negara</td>
                    <td>:</td>
                    <td>{{ data.negara }}</td>
                </tr>
                <tr>
                    <td>Provinsi</td>
                    <td>:</td>
                    <td>{{ data.nama_provinsi }}</td>
                </tr>
                <tr>
                    <td>Kota</td>
                    <td>:</td>
                    <td>{{ data.nama_kota }}</td>
                </tr>
                <tr>
                    <td>Kecamatan</td>
                    <td>:</td>
                    <td>{{ data.nama_kecamatan }}</td>
                </tr>
                <tr>
                    <td>Kelurahan</td>
                    <td>:</td>
                    <td>{{ data.nama_kelurahan }}</td>
                </tr>
              </table>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6">
              <table>
                <tr>
                    <td>No. HP</td>
                    <td>:</td>
                    <td>{{ data.no_telepon }}</td>
                </tr>
                <tr>
                    <td>NPWP</td>
                    <td>:</td>
                    <td>{{ data.npwp }}</td>
                </tr>
                <tr>
                    <td>Jenis Kelamin</td>
                    <td>:</td>
                    <td>{{ data.jen_kel }}</td>
                </tr>
                <tr>
                    <td>Agama</td>
                    <td style="width:10px">:</td>
                    <td>{{ data.agama }}</td>
                </tr>
                <tr>
                    <td>Etnis</td>
                    <td>:</td>
                    <td>{{ data.keterangan_etnis }}</td>
                </tr>
                <tr>
                    <td>Golongan Darah</td>
                    <td>:</td>
                    <td>{{ data.keterangan_golongan_darah }}</td>
                </tr>
                <tr>
                    <td>Pendidikan</td>
                    <td>:</td>
                    <td>{{ data.keterangan_pendidikan }}</td>
                </tr>
                <tr>
                    <td>Pekerjaan</td>
                    <td>:</td>
                    <td>{{ data.keterangan_pekerjaan }}</td>
                </tr>
                <tr>
                    <td>Tempat Kerja</td>
                    <td>:</td>
                    <td>{{ data.perusahaan_tempat_bekerja }}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td>{{ data.sc_email }}</td>
                </tr>
                <tr>
                    <td>WhatsApp</td>
                    <td>:</td>
                    <td>{{ data.sc_whatsapp }}</td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <hr/>
          <b-row>
            <b-col cols="12" sm="12" md="12" lg="12">
              <h3><strong>DATA KELUARGA</strong></h3>
            </b-col>
          </b-row>
          <hr/>
          <b-row>
            <b-col cols="12" sm="12" md="12" lg="12">
              <table>
                <tr>
                    <td>Nama Ayah</td>
                    <td>:</td>
                    <td>{{ data.nama_ayah }}</td>
                </tr>
                <tr>
                    <td>Nama Ibu</td>
                    <td>:</td>
                    <td>{{ data.nama_ibu }}</td>
                </tr>
                <tr>
                    <td>Nama Pasangan</td>
                    <td>:</td>
                    <td>{{ data.nama_pasangan }}</td>
                </tr>
                <tr>
                    <td>Nama Penanggung</td>
                    <td>:</td>
                    <td>{{ data.nama_penjamin }}</td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <hr/>
          <b-row>
            <b-col cols="12" sm="12" md="12" lg="12">
              <h3><strong>DATA BPJS</strong></h3>
            </b-col>
          </b-row>
          <hr/>
          <b-row>
            <b-col cols="12" sm="12" md="12" lg="12">
              <table>
                <tr>
                    <td>No. RM</td>
                    <td>:</td>
                    <td>{{ data.no_rm }}</td>
                </tr>
                <tr>
                    <td>No. BPJS</td>
                    <td>:</td>
                    <td>{{ data.no_jkn }}</td>
                </tr>
                <tr>
                    <td>Kode Asuransi</td>
                    <td>:</td>
                    <td>{{ data.kode_asuransi }}</td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-container>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-detail')">
            Tutup
          </b-button>

        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  import _ from "lodash"
  import axios from "axios";
  import { ipBackend } from "@/ipBackend";
  
  export default {
    name: "modalInput",
    props: ["data"],
    data() {
      return {
        selected: null,
        busy: false,
        button: "Simpan",
      };
    },
    methods: {
      simpan() {
        let vm = this;
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        axios
          .post(ipBackend + "msBank/register", vm.data)
          .then((res) => {
            console.log(res, "ini ress");
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$v.$reset();
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN MASTER BANK",
                showing: true,
              });
              this.$bvModal.hide("modal-input");
              this.reset();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      },
      reset() {
        this.$v.$reset();
        this.data = {
          nama_bank: "",
        };
      },
    },
  };
  </script>
  
  <style scoped>
    table, tr, td {
      
      padding: 3px;
      font-weight: bold;
      font-size: 15px
    }
  </style>