<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Data Pasien</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input
                  ><CIcon name="cil-plus" /> Tambah Data</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>
            <b-row>
              <b-col cols="3" md="3" lg="3">
                <label>Nama</label>
                <b-form-input v-model="dataFilter.nama_lengkap" @keydown.enter.prevent="getDatas()"></b-form-input>
              </b-col>
              <b-col cols="3" md="3" lg="3">
                <label>NIK</label>
                <b-form-input v-model="dataFilter.nik" @keydown.enter.prevent="getDatas()"></b-form-input>
              </b-col>
              <b-col cols="3" md="3" lg="3">
                <label>No. RM</label>
                <b-form-input v-model="dataFilter.no_rm" @keydown.enter.prevent="getDatas()"></b-form-input>
              </b-col>
              <b-col cols="3" md="3" lg="3">
                <label>No. RM Lama</label>
                <b-form-input v-model="dataFilter.no_rm_lama" @keydown.enter.prevent="getDatas()"></b-form-input>
              </b-col>
              <!-- <b-col cols="3" md="3" lg="3">
                <label>Alamat Sekarang</label>
                <b-form-input v-model="dataFilter.alamat_sekarang"></b-form-input>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col
                cols="2"
                md="2"
                lg="2"
                offset-lg="10"
                offset-md="10"
                style="padding-top: 20px"
              >
                <b-button
                  style="width: 100%"
                  @click="getDatas()"
                  variant="success"
                  >Cari
                </b-button>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <!-- <b-col cols="12" md="12" lg="12">
                <b-tabs card pills fill>
                  <b-tab :title="store.puskesmas.nama_puskesmas" active @click="dataFilter.ms_puskesmas_id = store.puskesmas_id, getDatas()"></b-tab>
                  <b-tab title="Semua Puskesmas" @click="dataFilter.ms_puskesmas_id = null, getDatas()"></b-tab>
                </b-tabs>
              </b-col> -->
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  responsive
                  show-empty
                  small
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <div class="d-flex nowrap">
                      <b-button
                        class="mr-1"
                        variant="info"
                        v-c-tooltip.hover.click="'Detail Data'"
                        @click="$router.push('./detail_rekam_medis/' + item.item.rm_id)"
                        ><CIcon name="cil-info" /></b-button
                      >
                      <b-button
                        class="mr-1"
                        variant="warning"
                        v-c-tooltip.hover.click="'Edit Data'"
                        v-b-modal.modal-edit
                        @click="is_data = item.item"
                        ><CIcon name="cil-pencil" /></b-button
                      >
                      <b-button 
                        class="mr-1"
                        variant="danger"
                        v-c-tooltip.hover.click="'Hapus Data'"
                        v-b-modal.modal-delete
                        @click="is_data = item.item"
                        ><CIcon name="cil-trash" /></b-button
                      >
                      <b-button
                        variant="success"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Update Satu Sehat'"
                        @click="updateSatuSehat(item.item)"
                        :disabled="item.item.satu_sehat_id != null"
                        ><CIcon name="cil-check" /> {{ item.actions }}</b-button
                      >
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- tableBusy {{tableBusy}}
            totalRows {{totalRows}}
            items {{items}} -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="5" offset-md="5">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-input
      :provinsi="provinsi"
      :golongan_darah="golongan_darah"
      :etnis="etnis"
      :pendidikan="pendidikan"
      :pekerjaan="pekerjaan"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-edit
      :is_data="is_data"
      :provinsi="provinsi"
      :golongan_darah="golongan_darah"
      :etnis="etnis"
      :pendidikan="pendidikan"
      :pekerjaan="pekerjaan"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-delete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-detail
      :data="data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import ModalInput from "./modalInput.vue";
import ModalEdit from "./modalEdit.vue";
import ModalDelete from "./modalDelete.vue";
import ModalDetail from "./modalDetail.vue";
import moment from "moment"
moment.locale("ID")
export default {
  components: {
    ModalInput,
    ModalEdit,
    ModalDelete,
    ModalDetail,
  },
  name: "master_pasien",
  data() {
    return {
      dataFilter: {
        nama_lengkap: null,
        nik: null,
        no_rm: null,
        alamat_sekarang: null,
        // puskesmas_id: null,
        // ms_puskesmas_id: null,
      },
      optionsStatus: [
          {value:"0", text:"Tidak Aktif"},
          {value:"1", text:"Aktif"},
      ],
      showing: false,
      variant: "success",
      msg: "",
      data: "",
      is_data: null,
      provinsi: [],
      etnis: [],
      pekerjaan: [],
      pendidikan: [],
      golongan_darah: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_lengkap",
          label: "Nama Pasien",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nik",
          label: "NIK",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "no_rm",
          label: "No. RM",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "no_rm_lama",
          label: "No. RM Lama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "no_bpjs",
          label: "No. BPJS",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "alamat_sekarang",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "satu_sehat_id",
          label: "IHS",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    store(){
      return this.$store.state
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  watch: {
    'currentPage'(newVar, oldVar){
      if(newVar != oldVar){
        this.getDatas()
      }
    },
    'perPage'(newVar, oldVar){
      if(newVar != oldVar){
        this.getDatas()
      }
    },
  },
  mounted() {
    // this.dataFilter.ms_puskesmas_id = this.store.puskesmas_id
    this.getDatas();
  },
  methods: {
    async getDatas() {
      let vm = this
      console.log('pasien')
      vm.tableBusy = true;
      // vm.items = []
      let pasien = await vm.$axios.post("/rm/list", {
        ...vm.dataFilter,
        halaman : vm.currentPage - 1 + '', 
        jumlah : vm.perPage + '',
      });
      console.log('pasien', pasien)
      vm.items = pasien.data.status == 200 ? pasien.data.data.map((x, idx) => {
        x.no = idx + 1 + (this.currentPage - 1) * this.perPage,
        x.ms_pendidikan_id = { ms_pendidikan_id: x.ms_pendidikan_id, nama_pendidikan: x.nama_pendidikan}
        x.ms_golongan_darah_id = { ms_golongan_darah_id: x.ms_golongan_darah_id, nama_golongan_darah: x.nama_golongan_darah}
        x.ms_etnis_id = { ms_etnis_id: x.ms_etnis_id, nama_etnis: x.nama_etnis}
        x.ms_provinsi_id = { ms_provinsi_id: x.ms_provinsi_id, nama_provinsi: x.nama_provinsi}
        x.ms_kota_id = { ms_kota_id: x.ms_kab_kota_id, nama_kota: x.nama_kota}
        x.ms_kecamatan_id = { ms_kecamatan_id: x.ms_kecamatan_id, nama_kecamatan: x.nama_kecamatan}
        x.ms_kelurahan_id = { ms_kelurahan_id: x.ms_kelurahan_id, nama_kelurahan: x.nama_kelurahan}
        x.tanggal_lahir = x.tanggal_lahir ? new Date(x.tanggal_lahir) : null
        x.tgl = moment(x.tgl_lahir).format("LL")
        if(x.jenis_kelamin == "L"){
          x.jen_kel = "Laki-laki"
        }else if(x.jenis_kelamin == "P"){
          x.jen_kel = "Perempuan"
        }
        return x
      }) : []
      vm.totalRows = parseInt(pasien.data.count)
      // console.log('vm.items', vm.items)
      // vm.totalRows = pasien.data.count
      // console.log('vm.totalRows', vm.totalRows)
      let provinsi = await vm.$axios.post("/ms_provinsi/list");
      vm.provinsi = provinsi.data.data
      // console.log('vm.provinsi', vm.provinsi);

      let golongan_darah = await vm.$axios.post("/ms_golongan_darah/list");
      vm.golongan_darah = golongan_darah.data.data
      // console.log('vm.golongan_darah', vm.golongan_darah);

      let pendidikan = await vm.$axios.post("/ms_pendidikan/list");
      vm.pendidikan = pendidikan.data.data
      // console.log('vm.pendidikan', vm.pendidikan);

      let etnis = await vm.$axios.post("/ms_etnis/list");
      vm.etnis = etnis.data.data
      // console.log(vm.items, 'ini item');
      // vm.totalRows = vm.items.length;
      vm.tableBusy = false;
    },
    updateSatuSehat(x){
      let vm = this
      console.log(x, 'ini x');
      vm.$axios.post("/satu_sehat/patient/get_patient", {
        nik: x.nik,
      })
      .then((res) => {
        console.log(res);
        if(res.data.status == 200 && res.data.message == "sukses"){
          if(res.data.data[0].entry.length){
            let id_satu_sehat = res.data.data[0].entry[0].resource.id
            vm.$axios.post("/rm/update", {
              id: x.rm_id,
              satu_sehat_id: id_satu_sehat,
            })
            .then((res) => {
              if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit("set_alert", { variant: "success", msg: "BERHASIL UPDATE DATA SATU SEHAT" })
                vm.getDatas()
              }
              else{
                vm.$store.commit("set_alert", { variant: "danger", msg: res.data.message })
              }
            })
            .catch((err) => {
              vm.$store.commit("set_alert", { variant: "danger", msg: err.message })
            })
          }else {
            vm.$store.commit("set_alert", { variant: "danger", msg: "PASIEN BELUM TERDAFTAR DI SATU SEHAT" })
          }
        }else {
          vm.$store.commit("set_alert", { variant: "danger", msg: res.data.message })
        }
      })
      .catch((err) => {
        vm.$store.commit("set_alert", { variant: "danger", msg: err.message })
      })
    },
    triggerAlert(event) {
      let vm = this;
      vm.getDatas
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
